/* eslint-disable import/no-relative-packages */
import React from 'react';
import { Tooltip, styled, tooltipClasses } from '@mui/material';

const LightTooltip = styled((props) => {
  const { className, ...otherProps } = props;
  return <Tooltip {...otherProps} classes={{ popper: className }} />;
})(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export default LightTooltip;

import * as React from 'react';
import { useRouter } from 'next/router';
import { IconButton, Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';

import { tokens } from '../../../../tokens';

const IconText = styled(Typography)(() => ({
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  fontSize: '0.575rem',
  fontWeight: 500,
  color: tokens.palette_primary,
  // '&:hover': {
  //   color: tokens.palette_secondary,
  // },
}));

const StyledIcon = styled(Box)(() => ({
  paddingTop: '2px',
  paddingBottom: '2px',
  marginBottom: '4px',
  height: '18px',
  width: '18px',
  color: tokens.palette_primary,
  '&:hover': {
    color: tokens.palette_secondary,
  },
}));

const TriggerButton = styled('button')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  border-radius: 12px;
  padding: 6px 12px;
  line-height: 1.5;
  background: transparent;
  border: 1px solid transparent;
  color: ${tokens.token_grey_100};
  &:hover {
    background: ${tokens.token_grey_10};
    border-color: ${tokens.token_grey_300};
  }
  &:focus-visible {
    border-color: ${tokens.token_blue_500};
    outline: 3px solid ${tokens.token_blue_200};
  }
  `
);


export default function ChatButton() {
    const router = useRouter(); 
  return (

      <Box>
        <TriggerButton
          // disabled
          aria-label="b_chat"
          onClick={() => router.push('/blockquiry_chat')}
        >
          <Stack alignItems="center">
            <StyledIcon>
              <MarkChatReadOutlinedIcon
                fontSize="small"
                sx={{ color: tokens.palette_secondary }}
              />
            </StyledIcon>
            <IconText variant="caption">ChatBot</IconText>
          </Stack>
        </TriggerButton>
      </Box>

  );
}

import React, { useState } from 'react';
import {
  Box,
  Drawer,
  Button,
  Typography,
  Paper,
  Divider,
  ListItemText,
  MenuItem,
  MenuList,
  IconButton,
  Stack,
  Link,
  Chip,
} from '@mui/material/';

import { styled } from '@mui/system';
import { tokens } from '../../../../../tokens';

import TwitterIcon from '@mui/icons-material/Twitter';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';

const SectionHead = styled(({ ...props }) => (
  <Typography component="div"  {...props} />
))`
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 16px;
  margin-left: 16px;
  color: ${tokens.token_typography_subhead};
`;

const SectionDivider = styled(Divider)`
  margin-bottom: 16px;
`;

const SubSectionHead = styled(({ ...props }) => (
  <Typography component="div" variant="h6" {...props} />
))`
  font-size: 15px;
  color: ${tokens.token_grey_800};
`;

const VerticalSeparator = styled(Box)`
  height: 6px;
`;

const ItemText = styled(Typography)(() => ({
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  fontSize: '0.775rem',
  fontWeight: 500,
  lineHeight: '1.50rem',
  letterSpacing: '0.01071em',

  color: tokens.token_typography_emphasized,
  marginRight: '8px',
}));

const AttributeText = styled(Typography)(() => ({
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  fontSize: '0.775rem',
  fontWeight: 400,
  lineHeight: '1.50rem',
  letterSpacing: '0.01071em',
  color: tokens.token_text_grey_on_white,
}));

const IconText = styled(Typography)(() => ({
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  fontSize: '0.575rem',
  fontWeight: 500,
  color: tokens.palette_primary,
  '&:hover': {
    color: tokens.palette_secondary,
  },
}));

const StyledIcon = styled(IconButton)(() => ({
  paddingTop: '2px',
  paddingBottom: '2px',
  height: '18px',
  width: '18px',
  color: tokens.palette_primary,
  '&:hover': {
    color: tokens.palette_secondary,
  },
}));

const StyledLink = styled(Link)(() => ({
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  fontSize: '0.685rem',
  fontWeight: 500,
  color: tokens.token_blue_700,
}));




export default function TemporaryDrawer() {
  const [state, setState] = useState({
    bottom: false, // Remove other anchor options
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: 'auto',
        display: { sx: 'block', md: 'none' },
        justifyContent: 'center',
        alignItems: 'center',
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Paper
        // variant="outlined"
        elevation={0}
        sx={{ px: '8px', py: '8px' }}
      >
        <SectionHead>Address Attribution</SectionHead>
        <Divider sx={{ '&.MuiDivider-root': { my: '4px', mx: '8px' } }} />
        <MenuList dense>
          <MenuItem>
            <ListItemText>
              <ItemText display="inline">Attribution:</ItemText>
              <AttributeText display="inline">KuCoin</AttributeText>
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>
              <ItemText display="inline">Attribution By:</ItemText>
              <StyledLink display="inline">blockquiry</StyledLink>
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>
              <ItemText display="inline">Label:</ItemText>
              <AttributeText display="inline">Exchange</AttributeText>
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>
              <ItemText display="inline">Type:</ItemText>
              <AttributeText display="inline">Centralized</AttributeText>
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>
              <ItemText display="inline">Country:</ItemText>
              <AttributeText display="inline">Seychelles</AttributeText>
            </ListItemText>
          </MenuItem>
          <Divider sx={{ '&.MuiDivider-root': { my: '4px', mx: '8px' } }} />
          <SectionHead>Contact Information and Policies</SectionHead>
          <Divider sx={{ '&.MuiDivider-root': { my: '4px', mx: '8px' } }} />
          <MenuItem>
            <ListItemText disableGutters>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Stack alignItems="center">
                  <StyledIcon>
                    <TwitterIcon fontSize="small" />
                  </StyledIcon>
                  <IconText variant="caption">Twitter</IconText>
                </Stack>

                <Stack alignItems="center">
                  <StyledIcon>
                    <EmailOutlinedIcon fontSize="small" />
                  </StyledIcon>
                  <IconText variant="caption">Email</IconText>
                </Stack>

                <Stack alignItems="center">
                  <StyledIcon>
                    <LanguageOutlinedIcon fontSize="small" />
                  </StyledIcon>
                  <IconText variant="caption">Web</IconText>
                </Stack>

                <Stack alignItems="center">
                  <StyledIcon>
                    <PolicyOutlinedIcon fontSize="small" />
                  </StyledIcon>
                  <IconText variant="caption">Privacy</IconText>
                </Stack>

                <Stack alignItems="center">
                  <StyledIcon>
                    <GavelOutlinedIcon fontSize="small" />
                  </StyledIcon>
                  <IconText variant="caption">Terms</IconText>
                </Stack>
              </Stack>
            </ListItemText>
          </MenuItem>
          <Divider sx={{ '&.MuiDivider-root': { my: '4px', mx: '8px' } }} />
          <SectionHead>Regional Regulation and Legislation</SectionHead>
          <Divider sx={{ '&.MuiDivider-root': { my: '4px', mx: '8px' } }} />
          <Stack
            direction="row"
            spacing={2}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: '16px',
            }}
          >
            <StyledLink>Regulation</StyledLink>
            <StyledLink href="/" underline="hover" target="_blank">
              Agencies
            </StyledLink>
            <StyledLink href="/" underline="hover" target="_blank">
              Legislation
            </StyledLink>
          </Stack>
          {/* <Divider sx={{ '&.MuiDivider-root': { my: '4px', mx: '8px' } }} /> */}
        </MenuList>
      </Paper>
    </Box>
  );

  return (
    <div>
      {/* Render the button for bottom anchor */}
      <Button onClick={toggleDrawer('bottom', true)}>Open from Bottom</Button>
      {/* Only render the Drawer with 'bottom' anchor */}
      <Drawer
        anchor="bottom"
        open={state.bottom}
        onClose={toggleDrawer('bottom', false)}
        sx={{ borderRadius: '6px' }}
      >
        {list('bottom')}
      </Drawer>
    </div>
  );
}

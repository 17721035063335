/* eslint-disable react/no-danger */
/* eslint-disable @next/next/no-script-component-in-head */
/* eslint-disable @next/next/inline-script-id */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useRef, useState } from 'react';
import Script from 'next/script';
import Head from 'next/head';
import Image from 'next/image';
import {
  Container,
  Stack,
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from '@mui/material/';
import { styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useRouter } from 'next/router';
import CopyToClipboard from '../src/components/accountdetails/copypaste/CopyToClipBoard';
// import { getSession } from 'next-auth/client';
import Footer from '../src/components/homepage/Footer';
import { tokens } from '../src/tokens';
import HomeAppBar from '../src/components/homepage/HomeAppBar';
import NodeInfoCardMobile from '../src/components/follow-the-money/graph/graph-main/components/NodeInfoCardMobile';



// eslint-disable-next-line no-empty-pattern

function ColorText({ children }) {
  return <span style={{ color: tokens.palette_secondary }}>{children}</span>;
}
const BlockTypo = styled(Typography)(() => ({
  fontFamily: 'play',
  opacity: 1,
  fontSize: '1.00rem',
  fontWeight: 700,
  lineHeight: '1.25rem',
  textAlign: 'center',
  paddingLeft: 0,
  whiteSpace: 'wrap',
  color: tokens.palette_primary,
}));

const Search = styled('div')(() => ({
  border: '1px solid',
  borderColor: tokens.token_search_border_grey,
  borderRadius: 24,
  maxHeight: 50,
  display: 'flex',
  alignItems: 'center',
  shadows: 2,
  '&:hover': {
    shadows: 2,
  },
}));

const SearchButton = styled(Button)(() => ({
  fontFamily: 'Roboto, Arial,  sans-serif',
  fontSize: '0.8750em',
  fontWeight: 500,
  textTransform: 'none',
  color: tokens.token_typography_regular_emphasized,
  border: '1px, solid',
  borderColor: tokens.token_grey_50,
  backgroundColor: tokens.token_grey_50,
  '&:hover,&:focus': {
    border: '1px solid',
    borderColor: tokens.token_grey_300,
    boxShadow: '0px 1px 1px rgb(0 0 0 / 10%)',
  },
}));

const WebTypo = styled(Typography)(() => ({
  fontFamily: 'play',
  opacity: 1,
  fontSize: '1.25rem',
  fontStyle: 'italic',
  fontWeight: 700,
  lineHeight: '1.50rem',
  textAlign: 'unset',
  paddingLeft: 0,
  whiteSpace: 'nowrap',
  color: tokens.palette_secondary,
}));

const TryItTypo = styled(Typography)(() => ({
  fontFamily: 'Roboto, Arial,sans-serif',
  fontSize: 'small', // 13px
  fontWeight: 500,
  color: tokens.token_typography_headline,
  textDecoration: 'solid',
  textDecorationThickness: 'auto',
  cursor: 'pointer',
}));

const TryItColorTypo = styled(Typography)(() => ({
  fontFamily: 'Roboto, Arial,sans-serif',
  fontSize: 'small', // 13px
  fontWeight: 500,
  color: tokens.token_typography_results_link,
  textDecoration: 'solid',
  textDecorationThickness: 'auto',
  cursor: 'pointer',
}));

const MessageTypo = styled(Typography)(() => ({
  fontFamily: 'Roboto, Arial,sans-serif',
  fontSize: '13px',
  color: tokens.token_typography_results_link,
  textDecoration: 'solid',
  textDecorationThickness: 'auto',
  cursor: 'pointer',
}));

function SimpleDialog(props) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Hello there! </DialogTitle>
      <DialogContent>
        {' '}
        <DialogContentText id="alert-dialog-description">
          Thanks for joining us on this journey! As we polish the gears of our
          blockquiry platform, a hiccup or two might surface. Curious about our
          big picture? Swing by our About page. Stay patient, stay excited –
          together, we're crafting a digital world that's safer by design!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

const testAddress = '0x96a08aceb70380777c51dfeea379bd33be1ab167';

export default function Home({ session }) {
  const router = useRouter();
  const searchInputRef = useRef(null);

  const search = (e) => {
    e.preventDefault();
    const term = searchInputRef.current.value;

    if (!term) return;
    router.push(`/search?term=${term}`)
    // router.push(`/search?term=${term}`).then(() => router.reload());
  };

  return (
    <>
      <Head>
        <title>
          blockquiry | Simplest way to get involved in the blockchain economy.
        </title>
        <meta
          name="description"
          content="Tools to make cryptocurrency interactions safe, familiar, and uncomplicated."
        />
        <meta
          name="keywords"
          content="block explorer, bitcoin explorer, blockchain explorer, transaction search, bitcoin address, digital currency, ethereum address, ether, ethereum blockchain, ethereum transaction, visualize blockchain transactions, report crypto crime, crypto scam alert, ethereum explorer"
        />
        <meta name="author" content="blockquiry" />
        <meta
          property="og:title"
          content="blockquiry | Simplest way to get involved in the blockchain economy."
        />
        <meta
          name="og:description"
          content="Tools to make cryptocurrency interactions safe, familiar, and uncomplicated."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/blockquiry92x21.png" />
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width" />
        {/* <meta name="robots" content="noindex, nofollow" /> */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'WebSite',
              name: 'blockquiry',
              description:
                'A search engine for finding information, about blockchain transactions, entities conducting business using the blockchain and the government agencies regulating them.',
              image: 'https://www.blockquiry.com/blockquiry_homepage.png',
              url: 'https://www.blockquiry.com/',
              potentialAction: {
                '@type': 'SearchAction',
                target:
                  'https://www.blockquiry.com/search?q={search_term_string}',
                'query-input': 'required name=search_term_string',
              },
              author: {
                '@type': 'Organization',
                name: 'blockquiry, LLC.',
              },
              publisher: {
                '@type': 'Organization',
                name: 'blockquiry, LLC.',
                logo: {
                  '@type': 'ImageObject',
                  url: 'https://www.blockquiry.com/_next/image?url=%2Fblockquiry272x64.png&w=384&q=75',
                  width: 272,
                  height: 64,
                },
              },
              dateModified: '2022-12-11',
              datePublished: '2020-03-01',
            }),
          }}
        />
      </Head>
      <Box>
        <HomeAppBar />
      </Box>

      <Container disableGutters maxWidth="sm">
        <Box
          sx={{
            mt: '170px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image
            width={272}
            height={64}
            src="/blockquiry272x64.png"
            alt="logo"
          />
          <Stack
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            {/* <WebTypo>beta</WebTypo> */}
          </Stack>
        </Box>

        <form>
          <Box sx={{ flexGrow: 1, paddingTop: '20px' }}>
            <Box m={1} maxWidth="584px" flexDirection="row">
              <Search
                sx={{
                  boxShadow: 0,
                  '&:hover': { boxShadow: '1px 1px 6px rgb(32 33 36 / 28%)' },
                }}
              >
                <IconButton
                  onClick={search}
                  type="submit"
                  sx={{ p: '10px', color: tokens.palette_primary }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <input
                  // disabled
                  style={{
                    width: '100%',
                    height: '35px',
                    border: 'none',
                    outline: 'none',
                    fontFamily: 'Roboto',
                    fontSize: '0.85rem',
                    fontWeight: '500',
                  }}
                  ref={searchInputRef}
                  type="text"
                  placeholder="Search a BTC or ETH address"
                />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                  onClick={() => {
                    if (searchInputRef.current) {
                      searchInputRef.current.value = '';
                    }
                  }}
                  sx={{ p: '10px', color: tokens.palette_secondary }}
                  aria-label="directions"
                >
                  <CloseOutlinedIcon />
                </IconButton>
              </Search>
            </Box>
          </Box>

          <Stack
            paddingTop="18px"
            direction="row"
            spacing={1}
            justifyContent="center"
            maxWidth="584px"
          >
            <SearchButton  type="submit" onClick={search}>
              Block Search
            </SearchButton>
            {/* <Tooltip title="Advanced Search Coming Soon" placement="bottom"> */}
            <Box>
              <SearchButton
                disabled
                type="submit"
                onClick={() => window.open('/advanced_search', '_blank')}
              >
                Advanced Search
              </SearchButton>
            </Box>
            {/* </Tooltip> */}
          </Stack>
        </form>
        <SimpleDialog />
        <Box
          maxWidth="584px"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            mt: '30px',
            mx: '20px',
          }}
        >
          <Box
            sx={{
              maxWidth: '300px',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              marginTop: '20px',
              padding: '5px',
            }}
          >
            {/* <NodeInfoCardMobile /> */}
            {/* <BlockTypo sx={{ textAlign: 'center' }}>
            <b>
              <ColorText>b</ColorText>lock<ColorText>&nbsp;•&nbsp;</ColorText>
              quir
              <ColorText>&nbsp;•&nbsp;</ColorText>y
            </b>{' '}
            an act of asking for blockchain information.
          </BlockTypo> */}
          </Box>
          {/* <Stack
          direction="row"
          spacing={0.25}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: '15px',
          }}
        >
          <TryItTypo flexDirection="row">
            Use our address if you don't have one handy:{' '}
          </TryItTypo>
          <TryItColorTypo>
            {' '}
            {testAddress.slice(0, 4)}...
            {testAddress.slice(testAddress.length - 4)}
          </TryItColorTypo>

          <CopyToClipboard stringToCopy={testAddress} />
        </Stack> */}
        </Box>
      </Container>
      <Footer />
    </>
  );
}

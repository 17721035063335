/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import * as React from 'react';
import Link from 'next/link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, useMediaQuery, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/system';
import { isMobile } from 'react-device-detect';
import { tokens } from '../../tokens';

const useMobileBreakpoint = (target = 600) => {
  const isDesktop = useMediaQuery(`(min-width:${target}px)`);
  return !isDesktop || isMobile;
};

function Copyright() {
  return (
    <>
      {' © '}
      <Link href="./about/blockquiry">
        <Button size="small" sx={{ textTransform: 'none', bgColor: 'none' }}>
          blockquiry {new Date().getFullYear()}.
        </Button>
      </Link>{' '}
    </>
  );
}

const Item = styled(Box)(() => ({
  px: 2,
  color: tokens.token_typography_regular,
  fontFamily: 'Roboto, Arial,  sans-serif',
  fontSize: '0.775rem',
  fontWeight: 500,
  whiteSpace: 'nowrap',
}));

export default function Footer() {
  const isMobile = useMobileBreakpoint(600);
  return (
    <Box
      component="footer"
      position="fixed"
      bottom={0}
      width="100%"
      sx={{ backgroundColor: tokens.token_grey_100, display: { xs: 'none', md: 'block' } }}
    >
      <Grid container>
        <Grid
          xs={6}
          sm={6}
          md={4}
          lg={4}
          xl={4}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: 5,
            paddingRight: 0,
          }}
        >
          <Link href="/about/blockquiry/howsearchworks">
            <Button
              size="small"
              sx={{ textTransform: 'none', bgColor: 'none' }}
            >
              How Search Works
            </Button>
          </Link>
        </Grid>
        <Grid
          xs={6}
          sm={6}
          md={4}
          lg={4}
          xl={4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: 5,
          }}
        >
          <Copyright />
        </Grid>

        <Grid
          xs={0}
          sm={0}
          md={4}
          lg={4}
          xl={4}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            px: 5,
            paddingRight: 5,
          }}
        >
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <Stack direction="row">
              <Link href="/about/blockquiry/privacy">
                <Button
                  size="small"
                  sx={{ textTransform: 'none', bgColor: 'none' }}
                >
                  Privacy
                </Button>
              </Link>

              <Link href="/about/blockquiry/terms">
                <Button
                  size="small"
                  sx={{ textTransform: 'none', bgColor: 'none' }}
                >
                  Terms
                </Button>
              </Link>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      {/* {isMobile && (
        <Grid xs={12} sm={12} md={4} lg={4} xl={4} flexGrow={1}>
          <Item>
            <Copyright />
          </Item>
        </Grid>
      )} */}
    </Box>
  );
}

import React, { useState } from 'react';
import { useRouter } from 'next/router';
import {
  IconButton,
  Menu,
  Grid,
  Stack,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AppsIcon from '@mui/icons-material/Apps';
import { tokens } from '../../../tokens';
import { styled } from '@mui/system';
// import ReportDialog from '../../accountdetails/reportcryptocrime/ReportDialog';
import ChatButton from '../../layouts/searchlayout/utils/ChatButton';
import MessageButton from '../../layouts/searchlayout/utils/MessageButton';
import ReportButton from '../../layouts/searchlayout/utils/ReportButton';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: theme.spacing(0.5),
  },
  icon: {
    marginBottom: theme.spacing(0.5),
  },
}));

const IconText = styled('div')({
  fontFamily: 'Roboto, Arial, sans-serif',
  fontSize: '0.675rem',
  letterSpacing: '0.0107142857em',
  fontWeight: 500,
  color: tokens.token_text_grey_on_white,
  lineHeight: '0.875rem',
  display: 'flex',
  justifyContent: 'center',
});

function AppMenu() {
  const router = useRouter();
  const { push, asPath } = useRouter();
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  return (
    <div>
      <IconButton
        // disabled
        aria-controls="menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          color: tokens.palette_primary,
          margin: '4px',
          '&.MuiButtonBase-root.MuiIconButton-root:hover': {
            borderRadius: '8px',
            padding: '8px',
            backgroundColor: tokens.token_hairline,
          },
        }}
      >
        <AppsIcon />
      </IconButton>

      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}

      >
        <Box
          sx={{
            // my: { xs: '4px', md: '4px' },
            // mx: { xs: '4px', md: '4px' },

            p: { xs: '4px', md: '4px' },
          }}
        >
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <Stack direction="row" alignItems="center" spacing={1}>
                <ChatButton />
                <ReportButton />
                <MessageButton />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Menu>
    </div>
  );
}

export default AppMenu;

/* eslint-disable react/prop-types */
import { useState } from 'react';
import { IconButton, Snackbar } from '@mui/material';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { tokens } from '../../../tokens';

function CopyToClipboard({ stringToCopy }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(stringToCopy);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        color="primary"
        style={{ padding: '1px', marginLeft: '4px' }}
      >
        <ContentCopyOutlinedIcon
          sx={{ color: tokens.token_grey_500, height: '14px', width: '14px' }}
        />
      </IconButton>
      <Snackbar
        message="Copied to clipboard"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={800}
        onClose={() => setOpen(false)}
        open={open}
        sx={{ backgroundColor: tokens.token_grey_300 }}
      />
    </>
  );
}

export default CopyToClipboard;

import React, { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { IconButton, Avatar, Box } from '@mui/material';
import { styled } from '@mui/system';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import LightTooltip from './LightTooltip';
import { tokens } from '../../../../tokens';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';

export default function MonetizationButton({ router, onClick }) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
    // add micro payment function  when the button is clicked
    if (onClick) {
      onClick();
    }
  };

  return (
    <LightTooltip title="">
      <Box>
        <IconButton
          //   disabled
          aria-label="money-button"
          onClick={handleClick} // add a click handler function
          sx={{
            margin: '4px',
            '&.MuiButtonBase-root.MuiIconButton-root:hover': {
              borderRadius: '8px',
              padding: '8px',
              backgroundColor: tokens.token_hairline,
              
            },
          }}
        >
          <Avatar
            variant="rounded"
            sx={{
              bgcolor: 'transparent',
              border: '1px solid',
              borderColor: clicked
                ? tokens.token_green_600
                : tokens.token_grey_300,
              width: '24px',
              height: '24px',
              // marginLeft: '16px',
              // flexDirection: 'column',
            }}
          >
            <PowerSettingsNewOutlinedIcon
              sx={{
                color: clicked ? tokens.token_green_600 : tokens.token_grey_300,
                width: '20px',
                height: '20px',
              }}
            />
          </Avatar>
        </IconButton>
      </Box>
    </LightTooltip>
  );
}
